<template>
  <div class="container mb-5">
    <div class="container-header mt-4 mb-4">
      <div class="row align-items-center px-3 g-0">
        <div class="col-md-8"><h2>Pengurusan Rujukan</h2></div>
        <div class="col-md-4">
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-outline-secondary w-50 me-2"
              @click="
                isEdit = false;
                openAddMedia();
              "
              data-bs-toggle="modal"
              data-bs-target="#addMediaModal"
            >
              Tambah Media +
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="container p-0">
        <div class="row mx-0 mb-2">
          <div class="col-md-3 p-1">
            <div class="bg-white rounded px-2 overflow-hidden">
              <div class="w-100 p-3">
                <h6 class="mb-3 fw-regular">Carian kandungan</h6>
                <div class="border rounded position-relative mb-4">
                  <input
                    type="text"
                    class="form-control border-0 input-searching m-0"
                    @change="searchMedia"
                    v-model="searchValue"
                    placeholder="Cari..."
                  />
                  <div class="position-absolute end-0 me-2 top-0 mt-1">
                    <i class="ri-search-line" style="color: #dee2e6" />
                  </div>
                </div>
                <small class="extra-small d-block fw-bold mt-4 ms-1 text-grey"
                  >TUNJUK:</small
                >
                <ul class="list-group mt-1">
                  <li
                    class="list-group-item border-0 rounded"
                    @click="filterMedia('')"
                    :style="
                      selectedFilter != ''
                        ? 'cursor: pointer;'
                        : 'cursor: pointer; background-color: #F3FEEE !important; color: #006F00 !important;'
                    "
                  >
                    <div class="d-flex align-items-center">
                      <i
                        :class="`ri-file-list-3-fill fs-6 me-2 text-grey`"
                        :style="
                          selectedFilter == '' && 'color: #006F00 !important'
                        "
                      />
                      <p
                        class="mb-0 text-grey"
                        :style="
                          selectedFilter == '' && 'color: #006F00 !important'
                        "
                      >
                        Semua
                      </p>
                    </div>
                  </li>
                  <li
                    class="list-group-item border-0 rounded"
                    @click="filterMedia('video')"
                    :style="
                      selectedFilter != 'video'
                        ? 'cursor: pointer;'
                        : 'cursor: pointer; background-color: #F3FEEE !important; color: #006F00 !important;'
                    "
                  >
                    <div class="d-flex align-items-center">
                      <i
                        :class="`ri-movie-fill fs-6 me-2 text-grey`"
                        :style="
                          selectedFilter == 'video' &&
                          'color: #006F00 !important'
                        "
                      />
                      <p
                        class="mb-0 text-grey"
                        :style="
                          selectedFilter == 'video' &&
                          'color: #006F00 !important'
                        "
                      >
                        Video
                      </p>
                    </div>
                  </li>
                  <li
                    class="list-group-item border-0 rounded"
                    @click="filterMedia('image')"
                    :style="
                      selectedFilter != 'image'
                        ? 'cursor: pointer;'
                        : 'cursor: pointer; background-color: #F3FEEE !important; color: #006F00 !important;'
                    "
                  >
                    <div class="d-flex align-items-center">
                      <i
                        :class="`ri-image-fill fs-6 me-2 text-grey`"
                        :style="
                          selectedFilter == 'image' &&
                          'color: #006F00 !important'
                        "
                      />
                      <p
                        class="mb-0 text-grey"
                        :style="
                          selectedFilter == 'image' &&
                          'color: #006F00 !important'
                        "
                      >
                        Infografik
                      </p>
                    </div>
                  </li>
                  <li
                    class="list-group-item border-0 rounded"
                    @click="filterMedia('flipbook')"
                    :style="
                      selectedFilter != 'flipbook'
                        ? 'cursor: pointer;'
                        : 'cursor: pointer; background-color: #F3FEEE !important; color: #006F00 !important;'
                    "
                  >
                    <div class="d-flex align-items-center">
                      <i
                        :class="`ri-book-fill fs-6 me-2 text-grey`"
                        :style="
                          selectedFilter == 'flipbook' &&
                          'color: #006F00 !important'
                        "
                      />
                      <p
                        class="mb-0 text-grey"
                        :style="
                          selectedFilter == 'flipbook' &&
                          'color: #006F00 !important'
                        "
                      >
                        e-Buku Ibadat Haji
                      </p>
                    </div>
                  </li>
                  <li
                    class="list-group-item border-0 rounded"
                    @click="filterMedia('pdf')"
                    :style="
                      selectedFilter != 'pdf'
                        ? 'cursor: pointer;'
                        : 'cursor: pointer; background-color: #F3FEEE !important; color: #006F00 !important;'
                    "
                  >
                    <div class="d-flex align-items-center">
                      <i
                        :class="`ri-file-pdf-fill fs-6 me-2 text-grey`"
                        :style="
                          selectedFilter == 'pdf' && 'color: #006F00 !important'
                        "
                      />
                      <p
                        class="mb-0 text-grey"
                        :style="
                          selectedFilter == 'pdf' && 'color: #006F00 !important'
                        "
                      >
                        PDF
                      </p>
                    </div>
                  </li>
                  <li
                    class="list-group-item border-0 rounded"
                    @click="filterMedia('archived')"
                    :style="
                      selectedFilter != 'archived'
                        ? 'cursor: pointer;'
                        : 'cursor: pointer; background-color: #F3FEEE !important; color: #006F00 !important;'
                    "
                  >
                    <div class="d-flex align-items-center">
                      <i
                        :class="`ri-archive-drawer-fill fs-6 me-2 text-grey`"
                        :style="
                          selectedFilter == 'archived' &&
                          'color: #006F00 !important'
                        "
                      />
                      <p
                        class="mb-0 text-grey"
                        :style="
                          selectedFilter == 'archived' &&
                          'color: #006F00 !important'
                        "
                      >
                        Archived
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-9 mb-4 p-1">
            <div class="px-1" style="min-height: 70vh">
              <div
                class="
                  row
                  mx-0
                  bg-white
                  rounded
                  justify-content-center
                  align-items-center
                  text-center
                "
                style="min-height: 70vh"
                v-if="isProcessing"
              >
                <p class="text-muted">Loading ...</p>
              </div>
              <div
                v-else
                class="row mx-0 bg-white rounded p-3"
                v-for="(fileType, i) in [
                  'flipbook',
                  'image',
                  'pdf',
                  'video',
                  'archived',
                ].filter((x) => {
                  if (selectedFilter != '') {
                    return x == selectedFilter;
                  } else return x;
                })"
                :key="i"
                style="margin-bottom: 12px"
              >
                <h6 class="px-2 mb-3 fw-bold">
                  {{ 
                    fileType == 'image' ? 'Infografik' 
                    : fileType == 'flipbook' ? 'e-Buku Ibadat Haji' 
                    : fileType.toUpperCase() 
                  }}
                </h6>
                <p
                  class="px-2 mb-4 text-muted"
                  v-if="
                    filteredMedias.filter((x) => x.type == fileType).length == 0 && fileType !== 'archived'
                  "
                >
                  Tiada.
                </p>
                <div
                  class="col-lg-2 col-md-6 p-1"
                  v-for="(media, i) in filteredMedias.filter(
                    (x) => fileType === 'archived' ? x.is_archived : x.type == fileType
                  )"
                  :key="'media' + i"
                  style="cursor: pointer"
                >
                  <div class="card overflow-hidden shadow-md">
                    <div
                      :class="
                        fileType == 'image'
                          ? 'position-relative w-100 bg-default-thumbnail image'
                          : fileType == 'video'
                          ? 'position-relative w-100 bg-default-thumbnail video'
                          : fileType == 'flipbook'
                          ? 'position-relative w-100 bg-default-thumbnail book'
                          : 'position-relative w-100 bg-default-thumbnail default'
                      "
                      :style="
                        media.background_image.data &&
                        `background-image: url(${media.background_image.data.attributes.url}) !important`
                      "
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between align-items-start">
                    <p
                      class="fw-regular text-left mt-2 text-right label-custom ps-2"
                    >
                      {{ media.title }}
                    </p>
                    <div class="btn-group mt-1">
                      <button
                        class="bi bi-three-dots-vertical"
                        type="button"
                        id="defaultDropdown"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="true"
                        aria-expanded="false"
                      ></button>
                      <ul
                        class="dropdown-menu p-0"
                        aria-labelledby="defaultDropdown"
                      >
                        <li
                          class="px-3 py-1 border-bottom"
                          @click="openEditMedia(media)"
                          data-bs-toggle="modal"
                          data-bs-target="#addMediaModal"
                        >
                          <i class="bi bi-eye"
                            ><span class="ms-2">Kemaskini</span></i
                          >
                        </li>
                        <li class="px-3 py-1" @click="deleteMedia(media)">
                          <i class="bi bi-trash"
                            ><span class="ms-2">Padam</span></i
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal"
      id="addMediaModal"
      tabindex="-1"
      aria-labelledby="addMediaModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <h5>{{ isEdit ? "Ubah Media" : "Tambah Media" }}</h5>
            <div class="row mx-0">
              <div class="col-12 pt-2">
                <h6 class="mt-2" v-if="!isEdit">Imej Hadapan</h6>
                <div class="file-upload-box" v-if="!isEdit">
                  <div class="file-upload-box-content" v-if="!mediaForm.bgRef">
                    <input
                      accept="image/png,image/jpeg,application/pdf/video/*/audio/mp3,audio/*"
                      type="file"
                      id="bgFile"
                      lang="es"
                      @change="bgUploaded"
                      ref="bgFile"
                      hidden
                    />
                    <label for="bgFile" class="title d-block"
                      >Klik untuk memuat naik</label
                    >
                    <span class="d-block">PDF, JPG atau PNG (max 10MB)</span>
                  </div>
                  <div
                    v-else
                    class="
                      row
                      d-flex
                      justify-content-center
                      align-items-center
                      pt-4
                    "
                  >
                    <div
                      class="list-group col-6"
                      style="width: 240px; border: 1px solid black"
                    >
                      <li class="list-group-item file-list">
                        <i class="bi bi-camera-reels file-icon"></i>
                        <span class="ms-2">{{ mediaForm.bgRef.name }}</span>
                        <span
                          class="float-end file-icon"
                          @click="mediaForm.bgRef = null"
                        >
                          <i class="fas fa-trash"></i>
                        </span>
                      </li>
                    </div>
                  </div>
                </div>
                <h6 class="mt-2" v-if="!isEdit">Fail</h6>
                <div class="file-upload-box" v-if="!isEdit">
                  <div
                    class="file-upload-box-content"
                    v-if="!mediaForm.fileRef"
                  >
                    <input
                      accept="image/png,image/jpeg,application/pdf/video/*/audio/mp3,audio/*"
                      type="file"
                      id="relatedFile"
                      lang="es"
                      @change="fileUploaded"
                      ref="relatedFile"
                      hidden
                    />
                    <label for="relatedFile" class="title d-block"
                      >Klik untuk memuat naik</label
                    >
                    <span v-if="mediaForm.type == 'video'" class="d-block">MP4 atau AVI</span>
                    <span v-else class="d-block">PDF, JPG atau PNG (max 10MB)</span>
                  </div>
                  <div
                    v-else
                    class="
                      row
                      d-flex
                      justify-content-center
                      align-items-center
                      pt-4
                    "
                  >
                    <div
                      class="list-group col-6"
                      style="width: 240px; border: 1px solid black"
                    >
                      <li class="list-group-item file-list">
                        <i class="bi bi-file-earmark-pdf file-icon"></i>
                        <span class="ms-2">{{ mediaForm.fileRef.name }}</span>
                        <span
                          class="float-end file-icon"
                          @click="mediaForm.fileRef = null"
                        >
                          <i class="fas fa-trash"></i>
                        </span>
                      </li>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Nama Media</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="mediaForm.title"
                    placeholder="Masukkan nama Media"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Jenis Media</label
                  >
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="mediaForm.type"
                  >
                    <option value="video">Video</option>
                    <option value="image">Infografik</option>
                    <option value="flipbook">e-Buku Ibadat Haji</option>
                    <option value="pdf">PDF</option>
                  </select>
                </div>
                <div class="form-group mt-3">
                  <label for="name" class="control-label mb-1"
                    >Akses Kepada</label>
                  <div v-for="access_type in user_access_types" 
                        :key="access_type.id"
                        class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="mediaForm.user_access_types" :value="access_type.id">
                    <label class="form-check-label">
                      {{ access_type.attributes.label }}
                    </label>
                  </div>
                  <!-- <div v-for="(role, i) in role_access" 
                        :key="role.name + i"
                        class="form-check">
                    <input class="form-check-input" type="radio" v-model="mediaForm.role_access" :value="role.value">
                    <label class="form-check-label">
                      {{ role.name }}
                    </label>
                  </div> -->
                </div>
                <!-- <div class="form-group mt-3" v-if="tags">
                  <label for="name" class="control-label mb-1">Tags</label
                  ><br /> -->
                <div class="form-group mt-3 custom-checkbox" v-if="isEdit">
                  <label for="name" class="control-label mb-1"
                    >Status Arkib</label
                  ><br />
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="mediaForm.is_archived"
                    id="archived"
                  />
                  <label class="form-check-label ms-3" for="kuiz">
                    {{ mediaForm.is_archived ? "Diarkib" : "Tidak Diarkib" }}
                  </label>
                </div>
                <!-- <div class="form-group mt-3" v-if="tags">
                  <label for="name" class="control-label mb-1"
                    >Tags</label
                  ><br/>
                  <div
                    class="form-check form-check-inline"
                    v-for="(tag, i) in tags"
                    :key="'tag' + i"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="'radio' + i"
                      :value="tag.id"
                      v-model="mediaForm.tags"
                    />
                    <label class="form-check-label">
                      {{ tag.name }}
                    </label>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="modal-footer mt-4">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Batal
            </button>
            <button
              v-if="!isEdit"
              type="button"
              class="btn btn-primary"
              @click="submitUploadFile"
            >
              Tambah
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary"
              @click="editMedia"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../utils/API";
import $ from "jquery";

export default {
  data() {
    return {
      isProcessing: true,
      searchValue: "",
      isEdit: false,
      role_access: [
        { name: "BH - Bakal Haji", value: "bh" },
        { name: "MP - Umum", value: "mp" },
        { name: "PEK - PEKTA", value: "pekta" },
        { name: "Petugas Haji", value: "petugas" },
        { name: "Umum", value: "public" },
      ],
      user_access_types: [],
      relatedFile: null,
      bgFile: null,
      toBeDeletedId: null,
      toBeUpdatedId: null,
      filteredMedias: [],
      medias: [],
      selectedFilter: "",
      tags: null,
      mediaForm: {
        fileRef: null,
        bgRef: null,
        currentFileName: null,
        currentBgName: null,
        title: "",
        type: null,
        description: null,
        background_image: null,
        file: null,
        role_access: null,
        user_access_types: [],
        is_archived: null,
        tags: [],
      },
    };
  },
  // watch: {
  //   mediaForm: {
  //     handler(_val){
  //       console.log('_val : ', _val)
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    goto(_path) {
      this.$router.push(_path);
    },
    async fetchData() {
      const res = await API.get(
        "gallery-files?populate=%2A&sort[0]=updatedAt%3Adesc&pagination[limit]=500"
      );

      const processedData = res.data.data.map((o) => {
        return Object.assign({}, { id: o.id }, o.attributes);
      });

      this.isProcessing = false;
      this.filteredMedias = [...processedData];

      this.medias = [...processedData];
    },
    searchMedia() {
      if (this.searchValue !== "") {
        this.filteredMedias = this.medias.filter((o) => {
          console.log(o.title);
          if (o.title != null && o.title != undefined) {
            return o.title
              .toUpperCase()
              .indexOf(this.searchValue.toUpperCase()) == -1
              ? false
              : true;
          }
        });
      } else {
        this.filteredMedias = [...this.medias];
      }
    },
    openEditMedia(media) {
      console.log(media)
      this.isEdit = true;
      this.toBeUpdatedId = media.id;
      this.mediaForm.title = media.title;
      this.mediaForm.type = media.type;
      this.mediaForm.is_archived = media.is_archived;
      this.mediaForm.role_access = media.role_access;

      this.mediaForm.user_access_types = []
      if(media.user_access_types && media.user_access_types.data.length > 0){
        media.user_access_types.data.forEach(access_type => {
          this.mediaForm.user_access_types.push(access_type.id)
        })
      }
    },
    openAddMedia() {
      this.mediaForm = {
        fileRef: null,
        bgRef: null,
        currentFileName: null,
        currentBgName: null,
        title: "",
        type: null,
        description: null,
        background_image: null,
        file: null,
        role_access: null,
        user_access_types: [],
        tags: [],
      };
    },
    async editMedia() {
      const res = await API.put("gallery-files/" + this.toBeUpdatedId, {
        data: !this.isEdit ? this.mediaForm : {
          title: this.mediaForm.title,   
          type: this.mediaForm.type,
          is_archived: this.mediaForm.is_archived,
          role_access: this.mediaForm.role_access,
          user_access_types: this.mediaForm.user_access_types
        }
      })
      if (res.status == 200) await this.fetchData();
      this.toBeUpdatedId = null;
      this.mediaForm = {
        fileRef: null,
        bgRef: null,
        title: "",
        type: null,
        description: null,
        background_image: null,
        file: null,
        user_access_types: [],
      };
      $("#addMediaModal").toggle();
      $(".modal-backdrop").remove();
    },
    async deleteMedia(media) {
      const res = await API.delete("gallery-files/" + media.id);
      if (res.toDelete) await this.fetchData();
    },
    fileUploaded() {
      if (this.$refs.relatedFile && this.$refs.relatedFile.files[0]) {
        this.mediaForm.fileRef = this.$refs.relatedFile.files[0];
      }
    },
    bgUploaded() {
      if (this.$refs.bgFile && this.$refs.bgFile.files[0]) {
        this.mediaForm.bgRef = this.$refs.bgFile.files[0];
      }
    },
    async uploadFile(file) {
      let formData = new FormData();
      formData.append("files", file);
      const resFileUpload = await API.post("upload", formData);
      return resFileUpload.data[0].id;
    },
    filterMedia(filter) {
      this.selectedFilter = filter
      if (filter !== "" && filter !== "archived")
        this.filteredMedias = [
          ...this.medias.filter((o) => {
            return (
              filter.includes(o.type) &&
              (o.is_archived == null || !o.is_archived)
            );
          }),
        ];
      else if (filter === "archived")
        this.filteredMedias = [
          ...this.medias.filter((o) => {
            return o.is_archived && o.is_archived != null && o.is_archived;
          }),
        ];
      else
        this.filteredMedias =
          this.searchValue.length > 0
            ? [...this.filteredMedias]
            : [...this.medias];
    },
    async submitUploadFile() {
      if (this.mediaForm.fileRef)
        this.mediaForm.file = await this.uploadFile(this.mediaForm.fileRef);
      if (this.mediaForm.bgRef)
        this.mediaForm.background_image = await this.uploadFile(
          this.mediaForm.bgRef
        );
      const res = await API.post("gallery-files", { data: this.mediaForm });
      if (res.data) await this.fetchData();
      this.mediaForm = {
        fileRef: null,
        bgRef: null,
        title: "",
        type: null,
        description: null,
        background_image: null,
        file: null,
      };
      $("#addMediaModal").toggle();
      $(".modal-backdrop").remove();
    },
  },
  async mounted() {
    const res01 = await API.get(`user-access-types`)
    this.user_access_types = res01.data.data

    await this.fetchData();
    const res = await API.get(`tags`);
    this.tags = res.data.data.map((o) => {
      return Object.assign({}, { id: o.id }, o.attributes);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";

.label-custom {
  font-size: 0.7rem !important;
  line-height: 0.78rem !important;
}

.bg-default-thumbnail {
  background-image: url("../../assets/img/random-img-placeholder/1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 225px;

  &.image {
    background-image: url("../../assets/img/random-img-placeholder/7.jpg") !important;
  }

  &.video {
    background-image: url("../../assets/img/random-img-placeholder/8.jpg") !important;
  }

  &.book {
    background-image: url("../../assets/img/random-img-placeholder/6.jpg") !important;
  }
}
.filter-list {
  li {
    border: 0;
    padding-top: 0;
    font-size: 18px;
    i {
      padding: 4px 8px;
      font-style: normal;
      span {
        padding-left: 8px;
      }
    }
  }
}

.custom-card {
  .card-img {
    height: 240px;
    img {
      height: 240px;
      object-fit: fill;
      object-position: center;
      opacity: 0.8;
    }
    .not-img {
      font-size: 160px;
      text-align: center;
    }
    .card-btn {
      padding: 0;
      padding-top: 4px;
      font-size: 24px;
      position: absolute;
      right: 0;
      button {
        background-color: transparent;
        border: 0;
      }
      li {
        font-size: 18px;
        i {
          padding: 4px 8px;
          font-style: normal;
          span {
            padding-left: 12px;
          }
        }
      }
    }
  }
  .card-label {
    text-align: center;
    padding-top: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>